import { named, optional, withDependencies } from '@wix/thunderbolt-ioc'
import {
	SiteFeatureConfigSymbol,
	PlatformEnvDataProvider,
	SiteWixCodeSdkSiteConfig,
	ExperimentsSymbol,
	Experiments,
	ViewerModelSym,
	ViewerModel,
} from '@wix/thunderbolt-symbols'
import { ISessionManager, SessionManagerSymbol } from 'feature-session-manager'
import { IPopupsLinkUtilsAPI, PopupsLinkUtilsAPISymbol } from 'feature-popups'
import { IMultilingualLinkUtilsAPI, MultilingualLinkUtilsAPISymbol } from 'feature-multilingual'
import { name } from '../symbols'

export const siteEnvDataProvider = withDependencies(
	[
		SessionManagerSymbol,
		ExperimentsSymbol,
		named(SiteFeatureConfigSymbol, name),
		ViewerModelSym,
		optional(PopupsLinkUtilsAPISymbol),
		optional(MultilingualLinkUtilsAPISymbol),
	],
	(
		sessionManager: ISessionManager,
		experiments: Experiments,
		{ pageIdToTitle }: SiteWixCodeSdkSiteConfig,
		viewerModel: ViewerModel,
		popupsLinkUtilsAPI: IPopupsLinkUtilsAPI,
		multilingualLinkUtilsAPI: IMultilingualLinkUtilsAPI
	): PlatformEnvDataProvider => {
		const {
			mode,
			site: { isResponsive },
		} = viewerModel

		return {
			get platformEnvData() {
				return {
					site: {
						applicationsInstances: sessionManager.getAllInstances(),
						experiments,
						isResponsive,
						pageIdToTitle,
						mode,
						popupPages: popupsLinkUtilsAPI?.getPopupPages(),
						multilingual: multilingualLinkUtilsAPI?.getMultilingualInfo(),
					},
				}
			},
		}
	}
)
